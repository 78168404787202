import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helper/hooks/scroll-to-top";
import Legal from "./components/pages/Legal";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home Pages
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
// Blog
// const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
// const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
// const Blogsingle = React.lazy(() => import("./components/pages/Blogsingle"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
// const Services = React.lazy(() => import("./components/pages/Services"));
// const Faq = React.lazy(() => import("./components/pages/Faq"));
// const Pricing = React.lazy(() => import("./components/pages/Pricing"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
// const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
// const Error = React.lazy(() => import("./components/pages/Error"));
// const Login = React.lazy(() => import("./components/pages/Login"));
// const Register = React.lazy(() => import("./components/pages/Register"));
// const Legal = React.lazy(() => import("./components/pages/Legal"));
// Listings
// const Listinggrid = React.lazy(() => import("./components/pages/Listinggrid"));
const Listinglist = React.lazy(() => import("./components/pages/Listinglist"));
// const Listingmap = React.lazy(() => import("./components/pages/Listingmap"));
const Listingdetailsone = React.lazy(() =>
  import("./components/pages/Listingdetailsone")
);
// const Listingdetailstwo = React.lazy(() =>
//   import("./components/pages/Listingdetailstwo")
// );
// const Listingdetailsthree = React.lazy(() =>
//   import("./components/pages/Listingdetailsthree")
// );
// const Submitlisting = React.lazy(() =>
//   import("./components/pages/Submitlisting")
// );
// const Comparelistings = React.lazy(() =>
//   import("./components/pages/Comparelistings")
// );
// // Agents
// const Agentarchive = React.lazy(() =>
//   import("./components/pages/Agentarchive")
// );
// const Agentdetails = React.lazy(() =>
//   import("./components/pages/Agentdetails")
// );
// const Profile = React.lazy(() => import("./components/pages/Profile"));
// const Profilelistings = React.lazy(() =>
//   import("./components/pages/Profilelistings")
// );
// const Profilesavedlistings = React.lazy(() =>
//   import("./components/pages/Profilesavedlistings")
// );
// // Agency
// const Agencyarchive = React.lazy(() =>
//   import("./components/pages/Agencyarchive")
// );
// const Agencydetails = React.lazy(() =>
//   import("./components/pages/Agencydetails")
// );

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          {/* Homepages */}
          <Route exact path="/" component={Homefour} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/listings/:displayType" component={Listinglist} />
          <Route
            path="/listing-details/:listingId"
            component={Listingdetailsone}
          />
          <Route path="/legal" component={Legal} />
          {/* Blog */}
          {/* <Route path="/blog-grid" component={Bloggrid} />
          <Route path="/blog-list" component={Bloglist} />
          <Route path="/blog-single" component={Blogsingle} /> */}
          {/* Pages */}
          {/* 
          <Route path="/services" component={Services} />
          <Route path="/faq" component={Faq} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/coming-soon" component={Comingsoon} />
          <Route path="/error-404" component={Error} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />

          {/* Listings */}
          {/* <Route path="/listing-grid" component={Listinggrid} />
          <Route path="/listing-map" component={Listingmap} />
          <Route path="/listing-details-v1" component={Listingdetailsone} />
          <Route path="/listing-details-v3" component={Listingdetailsthree} />
          <Route path="/submit-listing" component={Submitlisting} />
          <Route path="/compare-listings" component={Comparelistings} /> */}
          {/* Agents */}
          {/* <Route path="/agent-archive" component={Agentarchive} />
          <Route path="/agent-details" component={Agentdetails} />
          <Route path="/profile" component={Profile} />
          <Route path="/profile-listings" component={Profilelistings} />
          <Route
            path="/profile-saved-listings"
            component={Profilesavedlistings}
          /> */}
          {/* Agency */}
          {/* <Route path="/agency-archive" component={Agencyarchive} />
          <Route path="/agency-details" component={Agencydetails} /> */}
          {/* Maverick : Use this for handling errors / 404s */}
          {/* <Route path="/*" component={Error} /> */}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
