import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
  render() {
    return (
      <Fragment>
        {/* Logo */}
        <Link className="navbar-brand" to="/">
          {" "}
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
            alt="logo"
          />{" "}
        </Link>
        {/* Menu */}
        <ul className="navbar-nav">
          <li className="menu-item">
            <Link to="/">Home</Link>
          </li>
          <li className="menu-item">
            <Link to="/listings/buy">Buy</Link>
          </li>
          <li className="menu-item">
            <Link to="/listings/rent">Rent</Link>
          </li>
          <li className="menu-item">
            <Link to="/listings/all">All Listings</Link>
          </li>
          {/* <li className="menu-item menu-item-has-children">
            <Link to="/agent-archive">Our Agents</Link>
          </li> */}
          {/* <li className="menu-item menu-item-has-children">
            <Link to="/agency-archive">Agency</Link>
            <ul className="submenu">
              <li className="menu-item">
                {" "}
                <Link to="/agency-archive">Agency Archive</Link>{" "}
              </li>
              <li className="menu-item">
                {" "}
                <Link to="/agency-details">Agency Details</Link>{" "}
              </li>
            </ul>
          </li> */}
        </ul>
      </Fragment>
    );
  }
}

export default Menu;
