export const navigation = [
  {
    id: 1,
    linkText: "Home",
    child: false,
    link: "/",
  },
  {
    id: 2,
    linkText: "Buy",
    child: false,
    link: "/listings/buy",
  },
  {
    id: 3,
    linkText: "Rent",
    child: false,
    link: "/listings/rent",
  },
  {
    id: 4,
    linkText: "All Listings",
    child: false,
    link: "/listings/commercial",
  },
];
