import React, { Component } from "react";
import Sidebar from ".././../layouts/Blogsidebar";

class Content extends Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="post-content">
                {/* <h3>Who are we</h3> */}
                {/* <p>Our website is: http://androthemes.com/themes/react/acres</p> */}
                <h3>Privacy Policy</h3>
                <p>
                  <strong>Maverick Realty</strong> (“we,” “our,” or “us”) is
                  committed to protecting your privacy. This Privacy Policy
                  explains how we collect, use, disclose, and safeguard your
                  information when you visit our website [Insert Website URL],
                  engage with us via email, or use our services. Please read
                  this policy carefully to understand our views and practices
                  regarding your data and how we will treat it.
                </p>
                <h3>1. Information We Collect</h3>
                <h4>1.1. Personal Data</h4>
                <p>
                  We may collect personal data that you provide directly to us
                  when you:
                  <ul>
                    <li>● Register on our website.</li>
                    <li>● Subscribe to our newsletter.</li>
                    <li>● Fill out a contact form.</li>
                    <li>● Make a purchase or place an order.</li>
                    <li>● Communicate with us via email or other channels.</li>
                  </ul>
                </p>
                <p>
                  This information may include your name, email address, phone
                  number, mailing address, payment details, and any other
                  information you choose to provide.
                </p>
                <h4>1.2. Automatically Collected Data</h4>
                <p>
                  When you visit our website, we may automatically collect
                  certain information about your device, browsing actions, and
                  patterns. This may include:
                </p>
                <ul>
                  <li>● IP address.</li>
                  <li>● Browser type and version.</li>
                  <li>● Time zone setting.</li>
                  <li>● Operating system and platform.</li>
                  <li>
                    Information about your visit, including the URL clickstream
                    to, through, and from our site, products you viewed or
                    searched for, and page response times.
                  </li>
                </ul>
                <h4>1.3. Cookies and Tracking Technologies</h4>
                <p>
                  We use cookies and similar tracking technologies to track the
                  activity on our website and hold certain information. Cookies
                  are files with a small amount of data that may include an
                  anonymous unique identifier. You can instruct your browser to
                  refuse all cookies or to indicate when a cookie is being sent.
                </p>
                <h3>2. How We Use Your Information </h3>
                <p>We use the information we collect in the following ways:</p>
                <ul>
                  <li>● To provide, operate, and maintain our website.</li>
                  <li>● To improve, personalize, and expand our services.</li>
                  <li>
                    ● To communicate with you, including customer service, to
                    provide you with updates and other information relating to
                    our website and for marketing and promotional purposes.
                  </li>
                  <li>
                    ● To process your transactions and manage your orders.
                  </li>
                  <li>
                    ● To monitor and analyze usage and trends to improve your
                    experience.
                  </li>
                  <li>● To comply with legal obligations.</li>
                </ul>
                <h3> 3. Sharing Your Information</h3>
                <p>
                  We may share your personal data with third parties in the
                  following situations:
                </p>
                <ul>
                  <li>
                    ● Service Providers: We may share your information with
                    third-party service providers who perform services on our
                    behalf, such as payment processing, data analysis, email
                    delivery, hosting services, and customer service.
                  </li>
                  <li>
                    ● Legal Requirements: We may disclose your information if
                    required to do so by law or in response to valid requests by
                    public authorities (e.g., a court or government agency).
                  </li>
                  <li>
                    ● Business Transfers: If we are involved in a merger,
                    acquisition, or sale of all or a portion of our assets, your
                    information may be transferred as part of that transaction.
                  </li>
                </ul>
                <h3>4. Data Security</h3>
                <p>
                  We use administrative, technical, and physical security
                  measures to help protect your personal data. While we strive
                  to use commercially acceptable means to protect your personal
                  data, we cannot guarantee its absolute security.
                </p>
                <h3>5. Your Data Protection Rights</h3>
                <p>
                  Depending on your location, you may have the following rights
                  regarding your personal data:
                </p>
                <ul>
                  <li>
                    ● Right to Access: You have the right to request copies of
                    your personal data.
                  </li>
                  <li>
                    ● Right to Rectification: You have the right to request that
                    we correct any information you believe is inaccurate.
                  </li>
                  <li>
                    ● Right to Erasure: You have the right to request that we
                    erase your personal data under certain conditions.
                  </li>
                  <li>
                    ● Right to Restrict Processing: You have the right to
                    request that we restrict the processing of your personal
                    data under certain conditions.
                  </li>
                  <li>
                    ● Right to Object to Processing: You have the right to
                    object to our processing of your personal data under certain
                    conditions, especially if the processing is based on our
                    legitimate interests.
                  </li>
                  <li>
                    ● Right to Data Portability: You have the right to request
                    that we transfer the data we have collected to another
                    organization, or directly to you, under certain conditions.
                  </li>
                </ul>
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us at our contact information provided below.
                </p>
                <h3>6. Third-Party Websites</h3>
                <p>
                  Our website may contain links to third-party websites or
                  services that are not owned or controlled by us. We are not
                  responsible for the privacy practices or the content of these
                  third- party websites. We encourage you to review the privacy
                  policies of any third-party sites you visit.
                </p>
                <h3>7. Children's Privacy</h3>
                <p>
                  Our services are not directed to individuals under the age of
                  18. We do not knowingly collect personal data from children
                  under 18. If we become aware that we have collected personal
                  data from a child under 18 without verification of parental
                  consent, we will take steps to remove that information from
                  our servers.
                </p>
                <h3>8. Changes to This Privacy</h3>
                <p>
                  Policy We may update our Privacy Policy from time to time. We
                  will notify you of any changes by posting the new Privacy
                  Policy on this page. You are advised to review this Privac
                  Policy periodically for any changes. Changes to this Privacy
                  Policy are effective when they are posted on this page. If we
                  make significant changes to how we treat your personal
                  information, we will notify you through the email address you
                  provided or by placing a prominent notice on our website.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <Sidebar />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
