export const officeDetails = {
  dxb: {
    add1: "",
    add2: "",
    city: "DUBAI",
    country: "UAE",
    contactDetails: {
      reception: "+971-44579928",
      reception2: "+971-45542559",
      email: "info@maverickrealty.ae",
    },
  },
  sjh: {
    city: "SHARJAH",
    county: "UAE",
    contactDetails: {
      reception: "+971-123456789",
      email: "info@maverickrealty.ae",
    },
  },
};

export const contactinfo = [
  {
    id: 1,
    icon: "telephone",
    title: "Call Us",
    text: "Monday-Saturday: 9am - 6pm",
    btntext: "Call us",
    btnurl: `tel:${officeDetails.dxb.contactDetails.reception}`,
  },
  {
    id: 2,
    icon: "speech-bubble",
    title: "Email us",
    text: `${officeDetails.dxb.contactDetails.email}`,
    btntext: "Email us",
    btnurl: `mailto:info@maverickrealty.ae`,
  },
  {
    id: 3,
    icon: "location",
    title: "Visit Us",
    text: "Monday-Saturday: 9am - 6pm",
    btntext: "Directions",
    btnurl: "https://maps.app.goo.gl/gRcYPVNF6AL1THso8",
  },
];

export const SocialMedia = {
  instagram: "https://instagram.com/maverickrealty_",
};
